/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React, { useEffect, useContext, useState } from 'react';
import './BulkEdit.scss';
import Button from '@ingka/button';
import { useNavigate } from 'react-router-dom';
import { selectedPage } from '../header/Header';
import SitsContext from '../../context/SitsContext';
import '../../App.scss';
import { sourceLocaleName } from '../../constants';
import lablesAndnames from '../../lablesAndnames/labelsForComponents.json';

export default function BulkEditingselect(props) {
  const navigate = useNavigate();
  const sitsContext = useContext(SitsContext);
  const [cssClassname, setCssClassname] = useState('noTextSelected');
  // eslint-disable-next-line no-unused-vars
  const [noOfTextsCount, setNoOfTextsCount] = useState(0);
  // const [reRender, setReRender] = useState(false);

  // useEffect(() => {
  //   setReRender(!reRender);
  //   if (cssClassname === 'isTextSelected editbulkselect') {
  //     setReRender(true);
  //   } else if (cssClassname === 'noTextSelected') {
  //     setReRender(false);
  //   }
  // }, [cssClassname]);

  useEffect(() => {
    if (sitsContext.selectedtextsforbulkedit.length !== 0
      && (sitsContext.checkedAllFlag === false)) {
      // setNoOfTextsCount(sitsContext.selectedtextsforbulkedit.length);
      setCssClassname('isTextSelected editbulkselect');
    } else if (sitsContext.selectedtextsforbulkedit.length === 0
      && (sitsContext.checkedAllFlag === false)
    ) {
      setCssClassname('noTextSelected');
    }
  }, [sitsContext.selectedtextsforbulkedit]);

  useEffect(() => {
    if (sitsContext.checkedAllFlag) {
      // setNoOfTextsCount(sitsContext.dueinDataAllTextsInfo.length);
      setCssClassname('isTextSelected editbulkselect');
    } else if (sitsContext.checkedAllFlag === false) {
      if (sitsContext.selectedtextsforbulkedit.length !== 0) {
        setCssClassname('isTextSelected editbulkselect');
      } else {
        setCssClassname('noTextSelected');
      }
    }
  }, [sitsContext.checkedAllFlag]);

  useEffect(() => {
    if (props.uncheckedAll) {
      setCssClassname('noTextSelected');
      props.updateUnCheckedAll(false);
    }
  }, [props.uncheckedAll]);

  const deselectAll = () => {
    sitsContext.updateCheckedAllFlag(false);
    sitsContext.updateSelectedtextsforbulkedit([]);
    props.updateDeselectFlag(true);
    setCssClassname('noTextSelected');
  };

  const sendBulkDatatoEditor = () => {
    let arrayOftextIds = [];
    const selectedtextIds = sitsContext.selectedtextsforbulkedit.map((data) => {
      const routingIndividual = {
        textid: (data.textid).indexOf('-') === -1 ? data.textid : (data.textid).split('-')[0],
        source_locale_code: data.source_locale_code,
        target_locale_code: data.target_locale_code,
        object_type_short: data.object_type_short,
        isParentEnabled: true,
      };
      return routingIndividual;
    });

    const listOfIdsSelected = sitsContext.selectedtextsforbulkedit.map((data) => {
      const routingIndividual = {
        textId: (data.textid).indexOf('-') === -1 ? data.textid : (data.textid).split('-')[0],
        objectNameShort: data.object_type_short,
      };
      if ((data.textid).split('-')[1] === 'translated') {
        arrayOftextIds = arrayOftextIds.concat((data.textid).indexOf('-') === -1 ? data.textid : (data.textid).split('-')[0]);
      }
      return routingIndividual;
    });

    sitsContext.updateBulkEditbuttonStatus(true);
    sessionStorage.setItem('funcCalled', 'true');
    navigate('/Editorpage', {
      state: {
        selectedPageTitle: selectedPage,
        selectedTextData: selectedtextIds,
        sourceLocale: sourceLocaleName,
        targetLocale: sitsContext.selectedLocaleName,
      },
    });
    if (sitsContext.autoSaveHeaderChange !== null) {
      sitsContext.updateAutoSaveHeaderChange(null);
    }
    sitsContext.updateDataOfRelaseTexts(listOfIdsSelected, arrayOftextIds);
    // eslint-disable-next-line max-len
    sitsContext.updateEditorSearchPageLocationData(selectedPage, selectedtextIds, sourceLocaleName, sitsContext.selectedLocaleName);
    sitsContext.updateFilterDisplayFlag(false);
    sitsContext.updateInitialFilterFlag(false);
    sitsContext.updateNavigateEditToSearchPageFlag(`${selectedPage}`);
  };

  return (
    <div
      id="bulkEditpromt"
      className={
      sitsContext.checkedAllFlag ? 'isTextSelected editbulkselect'
        : (props.uncheckedAll ? 'noTextSelected' : cssClassname)
      }
    >
      <div className="display_content">
        <div className="display_content">
          <div className="textColor_White selectedtexts">
            {`${(sitsContext.checkedAllFlag
              ? sitsContext.dueinDataAllTextsInfo.length : sitsContext.selectedtextsforbulkedit.length)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} text${
              (sitsContext.checkedAllFlag
                ? sitsContext.dueinDataAllTextsInfo.length : sitsContext.selectedtextsforbulkedit.length)
                > 1
                ? 's' : ''
            } selected`}
          </div>
          <div className="deselectAll">
            <Button
              type="tertiary"
              inverseTheme
              text={lablesAndnames.button_deselectButtonText}
              onClick={() => deselectAll()}
            />
          </div>
        </div>
        <div className="bulkEditbutton">
          <Button
            type="tertiary"
            inverseTheme
            text={lablesAndnames.button_openEditorButtonText}
            onClick={() => sendBulkDatatoEditor()}
          />
        </div>
      </div>
    </div>
  );
}
